import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { modelsData, addFilterSelection  } from "../MOCK_DATA";
//import IntroVideo from "./IntroVideo";
const client_id = "101"; {/* ??? client_id */}

const FilterBar = ({
  makers,
  models,
  // makeNmodels,
  chassises,
  fuel_types,
  steering_wheels,
  transmissions,
  colors,
  onMakeFilter,
  onModelFilter,
  // haswarranties,
  // onHasWarrantyFilter,
  onWarrantyFilter,
  onMileageFilter,
  onChassisFilter,
  onFuel_TypeFilter,
  onSteering_WheelFilter,
  onTransmissionFilter,
  onColorFilter,
  onYearFilter,
}) => {
  const [filters, setFilters] = useState({
    make: "",
    has_warranty: "",
    model: "",
    warranty: "",
    mileage: "",
    chassis: "",
    fuel_type: "",
    steering_wheel: "",
    transmission: "",
    from: "",
    to: "",
  });

  const [selectedMaker, setSelectedMaker] = useState([]);
  function refreshPage() {
    window.location.reload(false);
  }
  /* const makerCalls = event => {
     handleInput("make");
     setSelectedMaker(event.target.value);
   } */

  function getModelList(value, dbModelsList, arr, prop) {
    const retList = [];
   // console.log("make ==> " + value);
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {        
        for(var j=0; j < dbModelsList.length; j++){     
          for(var k=0; k < arr[i].modelList.length; k++){
           // console.log("matching models ==> " + arr[i].modelList[k].value);
            if( arr[i].modelList[k].value === dbModelsList[j]) {     
              retList.push( arr[i].modelList[k].value);         
            }
          }
        }     
      }
    }
    return retList;
  }
 /*  function getIndex(value, dbModelsList, arr, prop) {
    const retList = [];
    console.log("dbModelsList ==> " + dbModelsList);
    
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {

        console.log("modelList ==> " + arr[i].modelList);
        
       
        for(var j=0; j < dbModelsList.length; j++){
          console.log("dbModelsList[] ==> " + dbModelsList[j]);
          for(var k=0; k < arr[i].modelList.length; k++){
           
            if( arr[i].modelList[k] === dbModelsList[j]) { 
              console.log("Match! " + arr[i].modelList[k]);
              retList.push( arr[i].modelList[k]);
             // return i;
            }
          }
        }
        console.log("retList ==> " + retList);
       // return i;
      
      }
    }
    return 0; //to handle the case where the value doesn't exist
  } */
  const handleInput = (field) => (event) => {
    const { value } = event.target;
    setFilters({
      ...filters,
      [field]: value,
    });

    switch (field) {
      case "make":
       // console.log("make::" + event.target.value + "::");
        addFilterSelection(client_id, "Marka", value);
        onMakeFilter(value);
        setSelectedMaker(value);
        break;
      case "model":
       // console.log("model::" + event.target.value + "::");
        addFilterSelection(client_id, "Model", value);
        onModelFilter(value);
        break;
      // case "has_warranty":
      //   onHasWarrantyFilter(value);
      //   break;
      case "warranty":
        onWarrantyFilter(value);
        break;
      case "mileage":
        onMileageFilter(value);
        break;
      case "chassis":
        addFilterSelection(client_id, "Kasa", value);
        onChassisFilter(value);
        break;
      case "fuel_type":
        addFilterSelection(client_id, "Yakıt Türü", value);
        onFuel_TypeFilter(value);
        break;
      case "steering_wheel":
        addFilterSelection(client_id, "Direksiyon", value);
        onSteering_WheelFilter(value);
        break;
      case "transmission":
        addFilterSelection(client_id, "Vites", value);
        onTransmissionFilter(value);
        break;
      case "color":
        addFilterSelection(client_id, "Renk", value);
        onColorFilter(value);
         // setSelectedColor(value);
          break;
      case "from":
        onYearFilter(value, "from");
        break;
      case "to":
        onYearFilter(value, "to");
        break;
      default:
        break;
    }
  };

  return (
    <div className="row main-lower-left-line">
      <div className="row my-5">
        {/*} <div className="row hopefully-upper right"> 
      <table style={{"display":"flex", "justifyContent":"center", "borderWidth":"1px", 'borderColor':"#aaae", 'borderStyle':'solid'}}>
          <IntroVideo/>
      </table>
  </div> */}
        <div className="col">
          <h5 className="border-bottom">Araç Arama</h5>
        </div>
        <div className="col-sm-12 my-2">
          <label htmlFor="maker"><b>Marka</b></label>
          <select
            className="form-control"
            id="make"
            onChange={handleInput("make")} >
            <option value="">Seçiniz</option>
            {makers.map((make) => (
              <option value={make} key={make}>
                {make}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 my-2">
          <label htmlFor="model"><b>Model</b></label>
          <select
            className="form-control"
            id="model"
            onChange={handleInput("model")}
          >
            <option value="">Seçiniz</option>
            {getModelList(selectedMaker, models, modelsData, "make").map((model) => (
              <option value={model} key={model}>
                {model}
              </option>
            ))}
          </select>
        </div>

        <div className="col-sm-12 my-2">
          <label htmlFor="chassis">Kasa Tipi</label>
          <select
            className="form-control"
            id="chassis"
            onChange={handleInput("chassis")}
          >
            <option value="">Seçiniz</option>
            {chassises.map((chassis) => (
              <option value={chassis} key={chassis}>
                {chassis}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 my-2">
          <label htmlFor="color">Rengi</label>
          <select
            className="form-control"
            id="color"
            onChange={handleInput("color")}
          >
            <option value="">Seçiniz</option>
            {colors.map((color) => (
              <option value={color} key={color}>
                {color}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 my-2">
          <label htmlFor="fuel_type">Yakıt Türü</label>
          <select
            className="form-control"
            id="fuel_type"
            onChange={handleInput("fuel_type")}
          >
            <option value="">Seçiniz</option>
            {fuel_types.map((fuel_type) => (
              <option value={fuel_type} key={fuel_type}>
                {fuel_type}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 my-2">
          <label htmlFor="steering_wheel">Direksiyon Türü</label>
          <select
            className="form-control"
            id="steering_wheel"
            onChange={handleInput("steering_wheel")}
          >
            <option value="">Seçiniz</option>
            {steering_wheels.map((steering_wheel) => (
              <option value={steering_wheel} key={steering_wheel}>
                {steering_wheel}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 my-2">
          <label htmlFor="transmission">Vites Türü</label>
          <select
            className="form-control"
            id="transmission"
            onChange={handleInput("transmission")}
          >
            <option value="">Seçiniz</option>
            {transmissions.map((transmission) => (
              <option value={transmission} key={transmission}>
                {transmission}
              </option>
            ))}
          </select>
        </div>
        <Button bsStyle="info" style={{justifyContent:"center", width: "150px" , 'borderStyle':'solid'}} onClick={refreshPage}><b>Filtreleri Temizle</b></Button>
      </div>
    {/* <IntroVideo/>  commented out upon request */}
    </div>
  );
};

export default FilterBar;
