import React, { useState } from "react";
//import { Dimensions } from "react-native";
import { IconButton } from "rsuite";
import { ExpandOutline } from '@rsuite/icons';
import "rsuite/dist/rsuite.min.css";
import Modal from 'react-modal';
import PictureForm from "./PictureForm"

Modal.setAppElement(document.getElementById('root'));

const slideStyles = {
  width: "95%",
  height: "95%",
  resizeMode: "contain",
  //borderRadius: "0px",
  backgroundSize: "95%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  // aspectRatio: 8 * 5,
  objectFit: "fill",
};

const slideStylesFull = {
  width: "100%",
  height: "93%",
  // height: windowHeight * 0.25,
  // width: windowWidth * 0.3,
  resizeMode: "stretch",
  //borderRadius: "0px",
  backgroundSize: "90%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  // aspectRatio: 8 * 5,
  objectFit: "fill",
};

const rightArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -60%)",
  right: "12px",
  fontSize: "35px",
  color: "#ffe",
  zIndex: 1,
  backgroundColor: "orange",
  cursor: "pointer",
};

const leftArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -60%)",
  left: "12px",
  fontSize: "35px",
  color: "#ffe",
  zIndex: 1,
  backgroundColor: "orange",
  cursor: "pointer",
};

const sliderStyles = {
  margin: "auto",
  position: "center",
  height: "96%",
  backgroundColor: "#C0C0C0",
  backgroundPosition: "center",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#C0C0C0",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "15px",
};

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex]?.url})`,
  };
  const openModal = () => {
    setIsOpen(true);
  }

  const ButtonStyle = { float: "center" };
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function afterOpenModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div style={sliderStyles} >  {/* style={sliderStyles}  className="slide-custom-inner" */}
      <IconButton icon={<ExpandOutline />} color="gray"
        appearance="primary" onClick={() => openModal()}>
      </IconButton>
      <div >
        <div onClick={goToPrevious} style={leftArrowStyles}>
          ❰
        </div>
        <div onClick={goToNext} style={rightArrowStyles}>
          ❱
        </div>
      </div>
      <div style={slideStylesWidthBackground}><br></br>

        <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={slideStylesFull}
            contentLabel="Resim">
            <div>
              <button variant="contained" style={slideStylesFull} className="float-right" onClick={closeModal}>X</button>
              <PictureForm imageURL={slides[currentIndex]?.url} />
            </div>
          </Modal>
        </div>

      </div>
      <div style={dotsContainerStyles} >
        {slides.map((slide, slideIndex) => (
          <div
            style={dotStyle}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >  ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;