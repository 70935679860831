import React from 'react';
// import whatsappLogo from '../whatsapp.jpg';
const DispBranchPhone = props => {
    const location = props.location;
    let phoneNo = "";
    let postsSection =   <>  </>
    if (location === "Mağusa"){
        phoneNo = "0542 853 6288";
        postsSection =  <> 
            <a href="tel:0392 444 3003">☎️ 444 3003</a>
            <a href="tel:0542 853 6288">&nbsp;&nbsp;&nbsp;☎️ {phoneNo}</a>
            <a href="tel:0548 877 7700">&nbsp;&nbsp;&nbsp;☎️ 0548 877 7700</a> 
        </>
    }
    if (location === "İskele"){
        phoneNo = "0542 852 6291";
        postsSection =   <> 
            <a href="tel:0392 444 3003">☎️ 444 3003</a>
            <a href="tel:0542 853 6288">&nbsp;&nbsp;&nbsp;☎️ {phoneNo}</a>       
        </>        
    }
    else
        phoneNo = "0542 853 6288";  // default : Mağusa
        
    return <>{postsSection}</>
    
    
};

export default DispBranchPhone;