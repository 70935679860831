import React from 'react';
import "./PictureForm.css";

const PictureForm = ({ imageURL }) => {

  return (
    <div classname="picture-large">
      <img
        src={imageURL}
        alt=""
      />
    </div>
  )
}
export default PictureForm;