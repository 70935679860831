import React from "react";
// import ReactDOM from 'react-dom';
import instagramLogo from '../instagram.jpg';
import ImageSlider from "./ImageSlider"
import Modal from 'react-modal';
import ModalQ from 'react-modal';
import ModalS from 'react-modal';
import DispWhatAppIcon from "./DispWhatAppIcon";
import DispEmailAppIcon from "./DispEmailIcon";
import DispFacebookIcon from "../facebook.png";
import './ImageClasses.css';
import DispLocation from "./DispLocation";
import {addVehicleOpen} from "../MOCK_DATA";
import {addCommunication} from "../MOCK_DATA";

const containerStyles = {
  // width: "850px",
  // height: "640px",
  width: "100%",
  height: "110%",  //  99vh  height: "150vh", "660px", //
  borderRadius: "2px",
  horizontalAlign: "left",
  // horizontalAlign: "top",
  // textAlign: "center",
  aspectRatio: 125 / 86,
  backgroundColor: "#C0C0C0",
  // backgroundSize: "cover",
  // objectFit:"fit",
  // backgroundPosition: "center",
};

const VehicleItem = ({ item }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [QmodalIsOpen, QsetIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      top: '10%',
      height: '100%',
      width: '100%',
      left: '5%',
      right: '10%',
      bottom: '3%',
      marginRight: '2%',
      transform: 'translate(-5%, -5%)',
      // backgroundcolor: "red",
    },
    alignItems: "center",
  };
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  // const [toggle, setToggle] = React.useState(false);
  // const toggleButton = () => setToggle(!toggle);

  Modal.setAppElement(document.getElementById('root'));
  ModalQ.setAppElement(document.getElementById('root'));
  ModalS.setAppElement(document.getElementById('root'));
  let subtitle;
  function openModal() {
    setIsOpen(true);
    addVehicleOpen(item);
  }
  // function QopenModal() {
  //  QsetIsOpen(true);
  // }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#805344';
  }
  // function QafterOpenModal() {
  //   QsetIsOpen(true);
  // references are now sync'd and can be accessed.
  // }
  function closeModal() {
    setIsOpen(false);
  }
  // function QcloseModal() {
  //  QsetIsOpen(false);
  // }
  function handleClick(e) {
    const client_id = "101"; 
    addCommunication(client_id, "phone inquiry");       
    //console.log('The phone link was clicked.');
  }

  return (
    <div className="col-sm-3"> {/* each returned vehicle box gets wider if this classname is removed*/}
         {/* 'test 3' shows on top of each item box   customStyles*/}
      <div className="resultSet-rounded-corners"> {/*  className="card my-2"*/}

        <button onClick={openModal}><img src={item?.image} className="image" style={{ width: "100%", height: "95%", objectFit: "fill" }} alt="" /></button>
        <div> {/*  className="card-body" or  className="container-fluid homepage-bgimage"  card-img-top */}
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles} /* Reduced the modal window size from screen wide to the specs. {customStyles} */
            // backgroundcolor= "#FF0000"
            contentLabel="Picture Gallery">
            <div style={{ backgroundColor: '#C0C0C0' }}> {/* className="App"  Modal internal outer area background color*/}
              <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={closeModal}>X</button>

              <h5 ref={(_subtitle) => (subtitle = _subtitle)}> (İlan# {item?.vehicleId}) {item?.make} {item?.model} - {item?.sub_model}</h5>
              <form>
                <div>
                  <div style={containerStyles} > {/* className ="slide-custom-inner" or style={containerStyles}    */}
                    {/* <ImageSlider slides={item?.picture_gallery_urls} />   <Slideshow/>*/}
                    <ImageSlider slides={item?.picture_gallery_urls} />
                  </div>
                  <br></br>
                  <div>
                  
                    <div style={{ marginLeft: '1%', fontSize: 18 }} className="row">
                      <div className="col-md-6">
                        <li>{item?.year}</li>
                        <li>{item?.mileage}</li>
                        <li>{item?.chassis}</li>
                        <li>{item?.color}</li>
                        <li>{item?.price} GBP</li>
                      </div>
                      <div className="col-md-6">
                        <li>{item?.engine_volume}</li>
                        <li>{item?.fuel_type}</li>
                        <li>{item?.steering_wheel}</li>
                        <li>{item?.transmission}</li>
                        <li>{item?.horse_power}</li>
                      </div>
                    </div>
                    <br></br>
                    <div style={{ marginLeft: '3%', backgroundColor: '#C0D0C0' }}>
                      <b>Diğer Özellikler: </b>
                      <b>İç Donanım: </b>{item?.interior_items}<br></br>
                      <b>Dış Donanım: </b>{item?.exterior_items}<br></br>
                      <b>Eğlence Sistemi: </b>{item?.entertainment_items}<br></br>
                    </div>
                    <p></p>
                    <div style={{ marginLeft: '3%' }} className="row">
                      <div className="col-md-1">
                        {item?.location}
                      </div>
                      <div className="col-md-1">
                        <DispLocation location={item?.location} />
                      </div>
                    </div>
                  </div>

                  <p> </p>
                  <div className="row">
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="row"></div>
                    <div className="row">

                      <table style={{ "display": "flex", "justifyContent": "center", "borderWidth": "0px", 'borderColor': "#aaae", 'borderStyle': 'solid' }}>
                        <tbody>
                          <tr >
                            <td>
                            <DispEmailAppIcon adNo={item?.vehicleId} location={item?.location} make={item?.make} model={item?.model} year={item?.year} />
                            </td>&nbsp;&nbsp;&nbsp;
                          <td>
                            <a href="https://www.facebook.com/profile.php?id=100040097241678" target="_blank" rel="noreferrer">
                          <img src={DispFacebookIcon} alt="example" className="rounded-corners" />
                        </a>&nbsp;&nbsp;&nbsp;
                            </td>
                            <td> <a href="https://www.instagram.com/firatserhatmotors.ltd/" target="_blank" rel="noreferrer">
                          <img src={instagramLogo} alt="example" className="rounded-corners" />
                        </a>&nbsp;&nbsp;&nbsp;
                            </td>

                            <td><DispWhatAppIcon location="Mağusa" /></td>
                          </tr>

                        </tbody>
                      </table>
                     
                    </div>
                    <p></p>
                    <div style={{resizeMode:'contain', color: "white", position: "relative", display: 'flex', justifyContent: 'center' }} className="row" >
          
           
          <div className="col-md-3"> <a href="tel:0392 444 30 03" onClick={() => {handleClick()}}>☎️ 444 30 03</a></div>
          <div className="col-md-3"> <a href="tel:0542 8536288" onClick={() => {handleClick()}}>☎️ 0542 8536288</a> </div>
          <div className="col-md-3"> <a href="tel:0542 8536291" onClick={() => {handleClick()}}>☎️ 0542 8536291</a> </div>
        </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          <h6 className="card-title">
            {item?.make} {item?.model} {item?.sub_model}
          </h6>
          {/* } <p className="card-text">{item?.year}  {item?.transmission} {item?.fuel_type}</p>   
          <p className="card-text">{item?.engine_volume} {item?.price} GBP {item?.color}</p> */}
          <div className="row">
            <div style={{ fontSize: 13 }} className="col">
              √ {item?.year}<br></br>
              √ {item?.transmission}<br></br>
              √ {item?.fuel_type}<br></br>
              √ {item?.steering_wheel}<br></br>
            </div>
            <div style={{ fontSize: 13 }} className="col">
              √ {item?.chassis}<br></br>
              √ {item?.engine_volume} cc<br></br>
              √ {item?.color}<br></br>
              √ {item?.price} GBP<br></br>
            </div>
          </div>
          <p><b>{item?.location}</b></p>
        </div>
      </div>
    </div>
  );
};
export default VehicleItem;