import React from 'react';
import emailIcon from '../email.png';
const DispEmailBranchIcon = props => {
    const location = props.location;
    const make = props.make;
    const model = props.model;
    const year = props.year;
    //let imageURL = "";
    let email = "";
    if (location === "Mağusa")
    email = "gediz.orhunoz@gmail.com";
    if (location === "İskele")
    email = "Hakki.altintas5561@gmail.com";
    else
    email = "gediz.orhunoz@gmail.com";  // default : Mağusa

    return <div>    
              <a href={"mailto:"+email+"?subject="+ location+" Şubesi&body="}  target="_blank" rel="noreferrer">
                     <img src={emailIcon} alt="example" className="rounded-corners"/>
              </a>       
    </div>;
};

export default DispEmailBranchIcon;