import axios from 'axios';

export const getIPData = async () => {
  var tmpIP = '';
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
  tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));

  return tmpIP; 
};

export const addFilterSelection = async (client_id, filter_name, filter_value) => {
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
  console.log("filter_name:"+filter_name);
  console.log("filter_value:"+filter_value);
  console.log("client_id:"+client_id);
  let newDate = new Date()
  const post ={
    filter_name : filter_name,
    filter_value: filter_value,
    client_id: client_id,
    date_time: newDate, 
    client_info : {
      ip_address : tmpIP
    } 
  }
  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addFilterSelection', post)
    console.log(res.data);
  } catch (e) {
    alert(e)
  }
}

export const FetchDataActive = async () => {
  const client_id = "101"; {/* ??? client_id */}
  const result = await axios(
    'https://data.mongodb-api.com/app/fsml-eeveh/endpoint/vehicles?arg1='+client_id,
  );

  addSiteOpen(client_id);
  return result; 
};

export const addCommunication = async (client_id, method) => {
  console.log("pre get ip");
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
  console.log("tmpIP"+tmpIP);
  console.log(client_id);
  let newDate = new Date()
  const post ={
    method : method,
    client_id: client_id,
    date_time: newDate, 
    client_info : {
      ip_address : tmpIP
    } 
  }
  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addCommunication', post)
    console.log(res.data);
  } catch (e) {
    alert(e)
  }
  console.log("post-ed ");
}

export const addSiteOpen = async (client_id) => {
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
  console.log("tmpIP"+tmpIP);
  console.log(client_id);
  let newDate = new Date()
  const post ={
    client_id: client_id,
    date_time: newDate, 
    client_info : {
      ip_address : tmpIP
    } 
  }
  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/openSite', post)
    console.log(res.data);
  } catch (e) {
    alert(e)
  }
}

export const addVehicleOpen = async (item) => {
  const result = await axios(
    'https://api.ipify.org/?format=json',
  );
 
 let tmpIP = eval(JSON.stringify(result.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
  //console.log("tmpIP"+tmpIP);
 // console.log(item.client_id);
  let newDate = new Date()
  const post ={
    client_id: item?.client_id,
    date_time: newDate, 
    vehicleId: item?.vehicleId,
    make: item?.make,
    model: item?.model,
    year: item?.year,
    price: item?.price,
    mileage: item?.mileage,
    client_info : {
      ip_address : tmpIP
    } 
  }

  try {
    const res = await axios.post('https://data.mongodb-api.com/app/fsml-eeveh/endpoint/addVehicleOpen', post)
    console.log(res.data);

  } catch (e) {
    alert(e)
  }
};

export const modelsData = [
  {
  make: "",
  modelList: [{value:"",label:""}]
},
{
  make: "Suzuki",
  modelList: [{value:"----", label:"----"},{value:"Jimmy Sierra", label:"Jimmy Sierra"},{value:"Swift", label:"Swift"}]
},
{
  make: "Mercedes",
  modelList: [{value:"----", label:"----"},{value:"115", label:"115"}, {value: "123", label:"123"}, {value:"190", label: "190"}, {value:"200", label: "200"}, {value:"220", label: "220"}, {value:"230", label: "230"}, {value:"240", label: "240"}, {value:"250", label: "250"}, {value:"260", label: "260"}, {value:"280", label: "280"}, {value:"300", label: "300"}, {value:"560", label: "560"}, {value:"A Serisi", label: "A Serisi"}, {value:"AMG GT", label: "AMG GT"}, {value:"B Serisi", label: "B Serisi"}, {value:"C Serisi", label: "C Serisi"}, {value:"CL", label: "CL"}, {value:"CLA", label: "CLA"}, {value:"CLC", label: "CLC"}, {value:"CLK", label: "CLK"}, {value:"CLS", label: "CLS"}, {value:"E Serisi", label: "E Serisi"}, {value:"EQE", label: "EQE"}, {value:"EQS", label: "EQS"}, {value:"S Serisi", label: "S Serisi"}, {value:"SL", label: "SL"}, {value:"SLC", label: "SLC"}, {value:"SLK", label: "SLK"}]
},
{
  make: "Lamborgini",
  modelList: [{value:"----", label:"----"},{value: "Huracan", label:"Huracan"}]
}, 
{
  make: "Volvo", 
  modelList: [{value:"----", label:"----"},{value: "440", label:"440"},{value: "S40", label:"S40"},{value: "S60", label:"S60"},{value: "S90", label:"S90"},{value: "V40", label:"V40"},{value: "V40 Cross Country", label:"V40 Cross Country"},{value: "V60", label:"V60"},{value: "V60 Cross Country", label:"V60 Cross Country"},{value: "V90 Cross Country", label:"V90 Cross Country"}]
}, 
{
  make: "Volkswagen",
  modelList: [{value:"----", label:"----"},{value: "Arteon", label:"Arteon"},{value: "Beetle", label:"Beetle"},{value: "Bora", label:"Bora"},{value: "EOS", label:"EOS"},{value: "Golf", label:"Golf"},{value: "Jetta", label:"Jetta"},{value: "Passat", label:"Passat"},{value: "Passat Variant", label:"Passat Variant"},{value: "Phaeton", label:"Phaeton"},{value: "Polo", label:"Polo"},{value: "Scirocco", label:"Scirocco"},{value: "Sharan", label:"Sharan"},{value: "Tiguan", label:"Tiguan"},{value: "Touran", label:"Touran"},{value: "Up Club", label:"Up Club"},{value: "VW CC", label:"VW CC"}]
},
{
  make: "Subaru",
  modelList: [{value:"----", label:"----"},{value: "Impreza", label:"Impreza"},{value: "Legacy", label:"Legacy"}]
},
{
  make: "Smart",
  modelList: [{value:"----", label:"----"},{value: "ForFour", label:"ForFour"},{value: "ForTwo", label:"ForTwo"},{value: "Roadster", label:"Roadster"}]
},
{
  make: "Rover",
  modelList: [{value:"----", label:"----"},{value: "220", label:"220"},{value: "25", label:"25"},{value: "420", label:"420"},{value: "45", label:"45"},{value: "820", label:"820"}]
},
{
  make: "Honda",
  modelList: [{value:"----", label:"----"},{value:"Accord", label:"Accord"},{value:"Civic", label:"Civic"},{value:"CRV", label:"CRV"},{value:"CRX", label:"CRX"},{value:"CR-Z", label:"CR-X"},{value:"Fit", label:"Fit"}]
},
{
  make: "Porche",
  modelList: [{value:"----", label:"----"},{value: "Cayenne", label:"Cayenne"},{value: "718", label:"718"},{value: "718 Boxster", label:"718 Boxster"},{value: "911", label:"911"},{value: "924", label:"924"},{value: "Panamera", label:"Panamera"},{value: "Taycan", label:"Taycan"}]
},
{
  make: "BMW",
  modelList: [{value:"----", label:"----"},{value:"1 Serisi", label:"1 Serisi"},{value:"2 Serisi", label:"2 Serisi"},{value:"3 Serisi", label:"3 Serisi"},{value:"4 Serisi", label:"4 Serisi"},{value:"5 Serisi", label:"5 Serisi"},{value:"6 Serisi", label:"6 Serisi"},{value:"7 Serisi", label:"7 Serisi"},{value:"8 Serisi", label:"8 Serisi"},{value:"i Serisi", label:"i Serisi"},{value:"M Serisi", label:"M Serisi"},{value:"Z Serisi", label:"Z Serisi"}]
},
{
  make: "Tesla",
  modelList: [{value:"----", label:"----"},{value: "Model 3", label:"Model 3"},{value: "Model S", label:"Model S"},{value: "Model X", label:"Model X"}]
},
{
  make: "Toyota",
  modelList: [{value:"----", label:"----"},{value:"RAV4", label:"RAV4"},{value:"Corolla", label:"Corolla"}]
},
{
  make: "Ferrari",
  modelList: [{value:"----", label:"----"},{value: "348", label:"348"},{value: "430", label:"430"},{value: "488", label:"488"},{value: "599 GT", label:"599 GT"},{value: "California", label:"California"}]
},
{
  make: "Lexus",
  modelList: [{value:"----", label:"----"},{value: "CT 200h", label:"CT 200h"},{value: "ES", label:"ES"},{value: "RC 200t", label:"RC 200t"}]
},
{
  make: "Kia",
  modelList: [{value:"----", label:"----"},{value: "Ceed", label:"Ceed"},{value: "Cerato", label:"Cerato"},{value: "Optima", label:"Optima"},{value: "Picanto", label:"Picanto"},{value: "Rio", label:"Rio"}]
},
{
  make: "Mitsubishi",
  modelList: [{value:"----", label:"----"},{value:"Airtrek", label:"AirTrek"},{value:"Colt", label:"Colt"},{value:"Barbarian", label:"Barbarian"},{value:"Mirage", label:"Mirage"},{value:"Outlander", label:"Outlander"}]
},
{
  make: "Ford",
  modelList: [{value:"----", label:"----"},{value:"Escort", label:"Escort"},{value:"Fiesta", label:"Fiesta"},{value:"Focus", label:"Focus"},{value:"Mondeo", label:"Mondeo"},{value:"Mustang", label:"Mustang"}]
},
{
  make: "Peugeot",
  modelList: [{value:"----", label:"----"},{value:"308", label:"308"}]
},
{
  make: "Audi",
  modelList: [{value:"----", label:"----"},{value:"A1", label:"A1"},{value:"A3", label:"A3"},{value:"A4", label:"A4"},{value:"A5", label:"A5"},{value:"A6", label:"A6"},{value:"Q5", label:"Q5"}]
},
{
  make: "Infinity",
  modelList: [{value:"----", label:"----"},{value:"Q 30", label:"Q 30"},{value:"Q 50", label:"Q 50"}]
},
{
  make: "MINI",
  modelList: [{value:"----", label:"----"},{value: "Cooper", label:"Cooper"},{value: "Cooper Clubman", label:"Cooper Clubman"},{value: "Cooper S", label:"Cooper S"},{value: "One", label:"One"}]
},
{
  make: "Alfa Romeo",
  modelList: [{value:"----", label:"----"},{value:"Giulietta", label:"Giulietta"},{value:"Spider", label:"Spider"}]
},
{
  make: "Citroen",
  modelList: [{value:"----", label:"----"},{value:"C1", label:"C1"},{value:"C2", label:"C2"},{value:"C3", label:"C3"},{value:"C4", label:"C4"},{value:"Xsara", label:"Xsara"}]
},
{
  make: "Fiat",
  modelList: [{value:"----", label:"----"},{value:"Brava", label:"Brava"},{value:"Bravo", label:"Bravo"},{value:"Coupe", label:"Coupe"}]
},
{
  make: "Hyundai",
  modelList: [{value:"----", label:"----"},{value:"i10", label:"i10"},{value:"i20", label:"i20"},{value:"i30", label:"i30"},{value:"Elantra", label:"Elantra"},{value:"Sonata", label:"Sonata"}]
},
{
  make: "Isuzu",
  modelList: [{value:"----", label:"----"},{value:"Gemini", label:"Gemini"}]
},
{
  make: "Jaguar",
  modelList: [{value:"----", label:"----"},{value:"F-Type", label:"F-Type"},{value:"XE", label:"XE"},{value:"XF", label:"XF"},{value:"XJS", label:"XJS"}]
},
{
  make: "Maserati",
  modelList: [{value:"----", label:"----"},{value:"Ghibli", label:"Ghibli"}]
},
{
  make: "Mazda",
  modelList: [{value:"----", label:"----"},{value:"Axela", label:"Axela"},{value:"323", label:"323"},{value:"Demio", label:"Demio"},{value:"2", label:"2"}]
},
{
  make: "Nissan",
  modelList: [{value:"----", label:"----"},{value:"Leaf", label:"Leaf"},{value:"Note", label:"Note"},{value:"March", label:"March"},{value:"Sylvia", label:"Sylvia"},{value:"X-Trail", label:"X-Trail"}]
},
];