import React from 'react';
import whatsappLogo from '../whatsapp.jpg';
import { addCommunication } from "../MOCK_DATA";

const DispWhatAppIcon = props => {
    const location = props.location;
    // const rightNow = getDay(hours);
    function handleClick(e) {
       const client_id = "101"; 
       addCommunication(client_id, "whatsapp call");       
       console.log('The whatsapp link was clicked.');
     }

    let imageURL = "";
    let phoneNo = "";
    if (location === "Mağusa")
           imageURL = "https://wa.me/905428536288";
    if (location === "İskele")
           imageURL = "https://wa.me/905428526291";
    else
           imageURL = "https://wa.me/905428536288";  // default : Mağusa

           phoneNo = imageURL.substring(15,30);

    return <div>    
              <a href={imageURL} onClick={() => {handleClick()}} target="_blank" rel="noreferrer">
                     <img src={whatsappLogo} width="39" height="30"alt="example" className="rounded-corners"/>
              </a>       
    </div>;
};

export default DispWhatAppIcon;