import React from 'react';
import locImage from "../LocationImage.jpg";

const DispLocation = props => {
    let url = "";
    const location = props.name;
    if (location === "Mağusa")
        url = "https://www.google.com/maps/place/35%C2%B007'33.6%22N+33%C2%B055'58.8%22E/@35.1259976,33.9308104,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x275f16d20e0b377c!8m2!3d35.1259976!4d33.9329991?hl=en";
    else  
        url = "https://www.google.com/maps?q=35.2773456,33.8977865&z=17&hl=en"; // (location === "İskele")

    return <div>    
              <a href={url} target="_blank" rel="noreferrer">
                     <img src={locImage} alt="example" className="rounded-corners"/>
              </a>       
    </div>;
};

export default DispLocation;