import React, { useState } from "react";
//import companyLogo from './Yeni_Logo_Kucuk.jpg';
import { addCommunication } from "./MOCK_DATA";
import instagramLogo from './instagram.jpg';
import Modal from 'react-modal';
import ModalQ from 'react-modal';
import ModalS from 'react-modal';
import "./App.css";
import VehicleItem from "./components/VehicleItem";
import { FetchDataActive } from "./MOCK_DATA";
import FilterBar from "./components/FilterBar";
import logo from './logo.jpg';
import mainBackground from './FSML_Background.jpg';
import mainBackgroundMobile from './BackgroundMobile.jpeg';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import ContactForm from './components/ContactForm'
import IntroVideo from "./components/IntroVideo";
import DispFacebookIcon from "./facebook.png";
import DispWhatAppIcon from "./components/DispWhatAppIcon";
import DispEmailBranchIcon from "./components/DispEmailBranchIcon";
import DispBranchPhone from "./components/DispBranchPhone";
import DispLocation from "./components/DispLocation";
import 'bootstrap/dist/css/bootstrap.css';
import Button from 'react-bootstrap/Button';
import {isMobile} from 'react-device-detect';

const customStyles = {
  content: {
    top: '30%',
    left: '28%',
    right: '20%',
    bottom: '20%',
    marginRight: '1%',
    transform: 'translate(-30%, -30%)',
  },
};
const customAboutUsStyles = {
  content: {
    top: '30%',
    left: '28%',
    right: '20%',
    bottom: '20%',
    marginLeft: '0%',
    transform: 'translate(-20%, -20%)',
    padding: '10px',
  },
};
const customContactUsStyles = {
  content: {
    height: '550px',
    top: '30%',
    left: '28%',
    right: '20%',
    bottom: '20%',
    marginLeft: '0%',
    transform: 'translate(-20%, -20%)',
    padding: '10px',
  },
};
let cloneData = [];
let selectedMaker = '';
let selectedModel = '';
let selectedChassis = '';
function App() {
  const [DBData, setDBData] = React.useState([]);
  React.useEffect(() => {
    FetchDataActive().then(res => {
      cloneData = eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:'));
      setDBData(eval(JSON.stringify(res.data, null, 2).replace(/"([^"]+)":/g, '$1:')))
    })
  }, []);

  const vehicleList = (DBData);
  let allData = vehicleList;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [QmodalIsOpen, QsetIsOpen] = React.useState(false);
  const [SmodalIsOpen, SsetIsOpen] = React.useState(false);

  Modal.setAppElement(document.getElementById('root'));
  ModalQ.setAppElement(document.getElementById('root'));
  ModalS.setAppElement(document.getElementById('root'));

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function QopenModal() {
    QsetIsOpen(true);
  }
  function QafterOpenModal() {
    QsetIsOpen(true);
    // references are now sync'd and can be accessed.
  }
  function QcloseModal() {
    QsetIsOpen(false);
  }
  function SopenModal() {
    SsetIsOpen(true);
  }
  function SafterOpenModal() {
    SsetIsOpen(true);
  }
  function ScloseModal() {
    SsetIsOpen(false);
  }

  allData = cloneData;
  const generateCarMakersDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.make))];
  };
  const generateModelsDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.model))];
  };
  const generateChassisDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.chassis))];
  };
  const generateFuel_TypeDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.fuel_type))];
  };
  const generateSteering_WheelDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.steering_wheel))];
  };
  const generateTransmissionDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.transmission))];
  };
  const generateColorDataForDropdown = () => {
    return [...new Set(allData.map((item) => item.color))];
  };
  const handleFilterMake = (make) => {
    selectedMaker = make;
    console.log("handleFilterMake selectedMaker:" + selectedMaker);
    const filteredData = allData.filter((item) => {
      const fullName = `${item.make}`;
      if (fullName.toLowerCase().includes(make.toLowerCase())) {
        return item;
      }
    });
    setDBData(filteredData);
  };

  const handleFilterModel = (model) => {
    selectedModel = model;
    console.log("handleFilterModel selectedModel:" + selectedModel);
    const filteredData = allData.filter((item) => {
      const keyName = `${item.model}`; // ${item.make} selectedMaker
      const keyName0 = `${item.make}`;
      if (keyName.toLowerCase().includes(model.toLowerCase())) {
        console.log("keyName0:" + keyName0);
        console.log(" selectedMaker.toLowerCase()" + selectedMaker.toLowerCase());
        if (keyName0.toLowerCase().includes(selectedMaker.toLowerCase())) {
          console.log("inner keyName0:" + keyName0);
          console.log("inner selectedMaker.toLowerCase()" + selectedMaker.toLowerCase());
          return item;
        }
      }
    });
    setDBData(filteredData);
  };
  const handleFilterColor = (color) => {
    const filteredData = vehicleList.filter((item) => {
    const keyName = `${item.color}`; // ${item.make}
    if (keyName.toLowerCase().includes(color.toLowerCase())
    ) {
      return item;
    }
  });
  setDBData(filteredData);
  };
  const handleFilterChassis = (chassis) => {
    selectedChassis = chassis;

    const filteredData = vehicleList.filter((item) => {
      const keyName0 = `${item.make}`;
      const keyName1 = `${item.model}`;
      const keyName2 = `${item.chassis}`;
      if (keyName0.toLowerCase().includes(selectedMaker.toLowerCase())) {
        if (selectedModel === "") {
          if (keyName2.toLowerCase().includes(chassis.toLowerCase())) {
            return item;
          }
        }
        else if (keyName1.toLowerCase().includes(selectedModel.toLowerCase())) {
          if (keyName2.toLowerCase().includes(chassis.toLowerCase())) {
            return item;
          }
        }
      }
    });
    setDBData(filteredData);
  };
  const handleFilterFuel_Type = (fuel_type) => {
    const filteredData = vehicleList.filter((item) => {
      if (item.fuel_type === fuel_type) {
        return item;
      }
    });
    setDBData(filteredData);
  };
  const handleFilterSteering_Wheel = (steering_wheel) => {
    const filteredData = vehicleList.filter((item) => {
      if (item.steering_wheel === steering_wheel) {
        return item;
      }
    });
    setDBData(filteredData);
  };
  const handleFilterTransmission = (transmission) => {
    const filteredData = vehicleList.filter((item) => {
      if (item.transmission === transmission) {
        return item;
      }
    });
    setDBData(filteredData);
  };
  const handleFilterYear = (year, field) => {
    const filteredData = vehicleList.filter((item) => {
      if (field === "from" && (item.year >= year)) {
        return item;
      }
      if (field === "to" && (item.year <= year)) {
        return item;
      }
    });
    setDBData(filteredData);
  };

  function handleClick(e) {
    const client_id = "101"; 
    addCommunication(client_id, "phone inquiry");       
    console.log('The phone link was clicked.');
  }

  return (
    <div className="container">
      <div>
        <div className="row">
        <img src= { mainBackground} alt="logo" />
          {/* <div className="Company-logo">   <a href="tel:0392 444 30 03">☎️ 444 30 03</a>
            <img style={{width:90, height:45}} src={companyLogo} alt="Fırat Serhat Motors Ltd. logo"/>      &nbsp;           
  </div> */}
          <div className="row-3" style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <h3 style={{ position: "absolute", top: "30px", color: "white", display: 'flex', justifyContent: 'center' }}>Fırat Serhat Motors Ltd. </h3> */}
          </div>


        </div>
        <div className="row" style={{ backgroundColor: '#eae6e6' }}>
          <div className="col-sm-3" >
            <FilterBar
              makers={generateCarMakersDataForDropdown()}
              models={generateModelsDataForDropdown()}
              chassises={generateChassisDataForDropdown()}
              fuel_types={generateFuel_TypeDataForDropdown()}
              steering_wheels={generateSteering_WheelDataForDropdown()}
              transmissions={generateTransmissionDataForDropdown()}
              colors={generateColorDataForDropdown()}
              onMakeFilter={handleFilterMake}
              onModelFilter={handleFilterModel}
              onChassisFilter={handleFilterChassis}
              onFuel_TypeFilter={handleFilterFuel_Type}
              onSteering_WheelFilter={handleFilterSteering_Wheel}
              onTransmissionFilter={handleFilterTransmission}
              onColorFilter={handleFilterColor}
              onYearFilter={handleFilterYear}
            />
          </div>
          <div className="col-sm-9" >
            <div className="row mt-5">
              {DBData.map((item) => (
                <VehicleItem item={item} key={item.vehicleId} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
        <p></p>
        <div className="col-md-3">
          <h6 style={{ fontSize: 16, "display": "flex", "justifyContent": "center" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Çalışma Saatleri</h6>
          <table style={{ fontSize: 14, "display": "flex", "justifyContent": "right", "borderWidth": "0px", 'borderColor': "#aaae", 'borderStyle': 'solid' }}>
            <tbody>
              <tr >
                <td >Pazartesi - Cuma:&nbsp;</td>
                <td>8:00 - 18:30&nbsp;&nbsp;</td>
              </tr>
              <tr>
                <td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cumartesi:&nbsp;</td>
                <td >8:00 - 14:00&nbsp;&nbsp;</td>
              </tr>
              <tr >
                <td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pazar:&nbsp;</td>
                <td >(Kapalı)&nbsp;&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-3">
          <h6 style={{ "display": "flex", "justifyContent": "right" }}>Bize ulaşın&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
          <table style={{ "display": "flex", "justifyContent": "right", "borderWidth": "0px", 'borderColor': "#aaae", 'borderStyle': 'solid' }}>
            <tbody>
              <tr >
                <td>
                  <a href="https://www.facebook.com/profile.php?id=100040097241678" target="_blank" rel="noreferrer">
                    <img src={DispFacebookIcon} alt="example" className="rounded-corners" />
                  </a>&nbsp;&nbsp;&nbsp;
                </td>
                <td><a href="https://www.instagram.com/firatserhatmotors.ltd/" target="_blank" rel="noreferrer">
                  <img src={instagramLogo} alt="example" className="rounded-corners" />
                </a>&nbsp;&nbsp;&nbsp;
                </td>

                <td><DispWhatAppIcon location="Mağusa" /></td>
              </tr>

            </tbody>
          </table>
        </div>
        <div className="col-md-5">
          <table style={{ fontSize: 14, "display": "flex", "justifyContent": "right", "borderWidth": "0px", 'borderColor': "#aaae", 'borderStyle': 'solid' }}>
            <tbody>
              <br></br>
              <tr >
                <td><a href="tel:0392 444 30 03" onClick={() => {handleClick()}}>☎️ 444 3003</a>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                <td><a href="tel:0542 8536288" onClick={() => {handleClick()}}>☎️ 0542 8536288</a>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                <td><a href="tel:0542 8536291" onClick={() => {handleClick()}}>☎️ 0542 8536291</a>&nbsp;  </td>
              </tr></tbody>
          </table>
        </div>
        <div className="col-md-4"> 
        {/*  <div className="row"> */}
        
      <div className="row-6" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="col-md-4">
              <button onClick={QopenModal} style={{ color: 'blue', border: 'none', float: 'center', backgroundColor: '#eae6e6' }}>Hakkımızda</button>
              <ModalQ
                isOpen={QmodalIsOpen}
                onAfterOpen={QafterOpenModal}
                onRequestClose={QcloseModal}
                style={customAboutUsStyles}
                contentLabel="Hakkımızda">
                <form>
                  <div className="App">
                    <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={QcloseModal}>X3</button>
                    <h5 style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                      <header className="App-header">
                        <img src={logo} alt="logo" />
                        <br></br>
                        <div>
                          <table style={{ "display": "flex", "justifyContent": "center", "borderWidth": "1px", 'borderColor': "#aaae", 'borderStyle': 'solid' }}>
                            <IntroVideo />
                          </table>
                        </div>
                        <br></br>
                        <p>&nbsp;MİSYON</p><p>
                          &nbsp;&nbsp;&nbsp;Fırat Serhat Motors Ltd, satış ağırlıklı bir şirket olmasıyla birlikte, aslında herşeyin
                          satış sonrasında başladığının bilincinde olup, satış ve satış sonrası departmanlarımız
                          uyum içerisinde çalışmakta ve müşterilerimizin problem, sıkıntı ve arzularını en iyi
                          şekilde karşılamaya çalışmaktadır. Nasıl ki satışa sunulan araçlarımızı şahsi araçlarımız
                          gibi hazırlayıp satışa sunuyorsak, satış sonrasında servis ve bakıma gelen müşteri
                          araçlarımızda da aynı özeni göstermekteyiz.
                        </p>

                        <p>&nbsp;VİZYON</p><p>
                          &nbsp;&nbsp;&nbsp;Otomotiv sektöründe siz sayın müşterilerimize daha hızlı daha iyi hizmet verebilmek adına
                          her geçen gün kadromuzu genişletmek ve büyümenin yanında, günümüz teknolojisine ayak
                          uydurup, geleceği takip ederek en son teknolojiyle müşterilerimizi buluşturmaktır.
                          Fırat Serhat Motors Ltd her zaman yenilikler, kampanyalar ve rakipsiz garanti kapsam ve
                          süreleriyle en iyi olmuş ve olmaya da devam edebilmek için müşterilerimizin desteğiyle
                          hedeflediği yolda ilerlemeye devam edecektir.
                        </p>
                        <br></br>
                        MÜŞTERİLERİMİZ VE BİZ KOCAMAN BİR AİLEYİZ
                        <p>
                          <p>&nbsp;&nbsp;&nbsp;Saygılar,</p>
                          Değer Alibey
                        </p>
                        Koordinatör
                        <br></br>  <br></br>
                        <a href="https://www.instagram.com/firatserhatmotors.ltd/" target="_blank" rel="noreferrer">
                          <img src={instagramLogo} alt="example" className="rounded-corners" />
                        </a>
                      </header>
                    </h5>
                  </div>
                </form>
              </ModalQ>
            </div>
            <div style={{ display: 'flex', justifyContent: 'left' }} className="col-md-4">
              <button onClick={SopenModal} style={{ color: 'blue', border: 'none', float: 'right', backgroundColor: '#eae6e6' }}>Şubeler&nbsp;&nbsp;&nbsp;&nbsp;</button>
              <ModalS
                isOpen={SmodalIsOpen}
                onAfterOpen={SafterOpenModal}
                onRequestClose={ScloseModal}
                style={customAboutUsStyles}
                contentLabel="Şubelerimiz">
                <form>
                  <div className="App">
                    <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={QcloseModal}>X</button>
                    <h5 style={{ display: 'abolute', justifyContent: 'center', padding: '10px' }}>
                      <header>
                        <div className="row">
                          <div className="col-md-3">
                          </div>
                          <div className="col-md-2">
                          </div>
                          <div className="col-md-2">
                            <img src={logo} alt="logo" />
                          </div>
                          <div className="col-md-4">
                          </div>
                        </div>
                        <br></br>
                        <h2><p>Şubelerimiz</p> </h2>
                        <h4>Fırat Serhat Motors Ltd aşağıdaki şubelerimizde sizlere hizmek vermekten gurur duymaktadır.</h4><p>
                          <br></br>
                          <div className="AppSub">
                            <b>Mağusa</b> <br></br>
                            İsmet İnönü Blv, Gazimağusa 99450
                            <div className="row">
                              <div className="col-md-3">
                                <DispLocation location="Mağusa" />
                              </div>
                              <div className="col-md-1">
                                <DispWhatAppIcon location="Mağusa" />
                              </div>
                              <div className="col-md-1">
                                <DispEmailBranchIcon location="Mağusa">Mağusa</DispEmailBranchIcon>
                              </div>
                              <div className="col-md-7">
                                <DispBranchPhone location="Mağusa"></DispBranchPhone>
                              </div>
                            </div>
                          </div>
                          <div className="App">
                            <br></br>
                          </div>
                          <div className="AppSub">

                            <b>İskele</b><br></br>
                            Şht. ilker Karter Caddesi, Yeni İskele
                            <div className="row"> <div className="col-md-3">
                              <DispLocation location="İskele" />
                            </div>
                              <div className="col-md-1">
                                <DispWhatAppIcon location="İskele" />
                              </div>
                              <div className="col-md-1">
                                <DispEmailBranchIcon location="İskele">İskele</DispEmailBranchIcon>
                              </div>
                              <div className="col-md-7">
                                <DispBranchPhone location="İskele"></DispBranchPhone>
                              </div>
                            </div>
                          </div>
                        </p>

                        <br></br>  <br></br>
                        <div className="row" style={{ color: "white", justifyContent: 'center' }} >
                          <div className="col-md-2">
                            <a href="https://www.facebook.com/profile.php?id=100040097241678" target="_blank" rel="noreferrer">
                              <img src={DispFacebookIcon} alt="example" className="rounded-corners" />
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a href="https://www.instagram.com/firatserhatmotors.ltd/" target="_blank" rel="noreferrer">
                              <img src={instagramLogo} alt="example" className="rounded-corners" />
                            </a>
                          </div>
                        </div>
                        <div className="App">
                          <div style={{ color: "white", display: 'flex', justifyContent: 'center' }} className="row">☎️444 3003&nbsp; ☎️0542 8536288 &nbsp;☎️0542 8536291</div>
                        </div>
                      </header>
                    </h5>
                  </div>
                </form>
              </ModalS>
            </div>
            <div className="col-md-4">
              <button onClick={openModal} style={{ color: 'blue', border: 'none', float: 'left', backgroundColor: '#eae6e6' }}>İletişim&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customContactUsStyles}
                contentLabel="İletişim">
                <div className="App">
                  <button variant="contained" style={{ float: 'right' }} className="float-right" onClick={closeModal}>X</button>
                  <ContactForm />
                </div>
              </Modal>
            </div>
          </div>
      </div>
      
       
    </div> 
        {/*  </div> 
      {/* Vendor footer back to the product page */}
      <div>
        <div className="row">
          <br></br>
        </div>
        <div className="row" style={{ fontSize:12, display: 'flex', textAlign: "center", justifyContent: 'center' }}>
          <a href="https://otofab.com/urun" target="_blank" rel="noreferrer">
            OtoFab, built by CIS Analytic. </a>
        </div>
      </div>
    </div>
  );
}

export default App;
