import React from 'react';
import emailIcon from '../email.png';
import { addCommunication } from "../MOCK_DATA";

const DispEmailIcon = props => {
    const location = props.location;
    const make = props.make;
    const model = props.model;
    const year = props.year;
    const adNo = props.adNo;
    //let imageURL = "";
    function handleClick(e) {
        const client_id = "101"; 
        addCommunication(client_id, "email inquiry");       
        console.log('The email link was clicked.');
      }

    let email = "";
    if (location === "Mağusa")
    email = "gediz.orhunoz@gmail.com";
    if (location === "İskele")
    email = "Hakki.altintas5561@gmail.com";
    else
    email = "gediz.orhunoz@gmail.com";  // default : Mağusa

    return <div>    
              <a href={"mailto:"+email+"?subject="+ "(Ilan# "+adNo+") "+location+" Araba:"+year + " " + make+ " "+ model+"&body="} onClick={() => {handleClick()}}  target="_blank" rel="noreferrer">
                     <img src={emailIcon} alt="example" className="rounded-corners"/>
              </a>       
    </div>;
};

export default DispEmailIcon;