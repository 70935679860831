const src =
  "https://res.cloudinary.com/cisanalytic/video/upload/v1666101508/FiratOto/firat_final_wlrjdb.mp4";

const IntroVideo = () => {
  return (
    
    <video controls width="95%" height="95%">
      <source src={src} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
    
  );
};

export default IntroVideo;