import React from 'react'
import { useForm, ValidationError } from '@formspree/react';
import companyLogo from '../Yeni_Logo_Kucuk.jpg';
import { addCommunication } from "../MOCK_DATA";
import "./ContactForm.css";

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Gönder')
  const [state, handleSubmit] = useForm("mlevbldw");
  const options = [{value: "NoValue", label:"----"},
  {value: "Buy", label:"Araç bakıyorum"},
  {value: "Sell", label:"Aracımı satmayı düşünüyorum"},
  {value: "PriceInquiry", label:"Fiyat araştırması yapıyorum"},
  {value: "Other", label:"Diğer"}
  ];
  const client_id = "101";

  const sendComm = (e) => {
    console.log("submit");
    addCommunication(client_id, "inquiry form");
     // FSML post ==> https://public.herotofu.com/v1/211a8ad0-3da1-11ed-a10f-d1a38bd15d37
    // Ornek post ==> https://public.herotofu.com/v1/e1b88170-e369-11ed-8a44-d1f0173776c3
  }
  return (
    <div>
        <img src={companyLogo} className="center" alt="logo" />
     <h3 style={{display: 'flex', justifyContent:'center', color:'white'}} >Fırat Serhat Motors Ltd.</h3>
    <div className="container mt-5">
    
      <h4 className="mb-3" style={{ color:'white'}}>Bize Ulaşın </h4>
      <form onSubmit={sendComm} action="https://public.herotofu.com/v1/211a8ad0-3da1-11ed-a10f-d1a38bd15d37" method="post">
        <div className="mb-3">
          <label className="form-label" htmlFor="name"  style={{ color:'white'}}>
            İsim:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;</label>
          <input type="text" name="name" id="name" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email"  style={{ color:'white'}}>
            Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <input type="email" name="email" id="email" required />
        </div>     
        <div className="mb-3">
          <label className="form-label" htmlFor="category"  style={{ color:'white'}}>
            Kategori:&nbsp;
          </label>  

          <select name="category" id="category">
            {options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="subject"  style={{ color:'white'}}>
            Başlık: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <input type="text" name="subject" id="subject" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message"  style={{ color:'white'}}>
            Mesajınız
          </label>
          <textarea className="form-control" name="message" id="message" required />
        </div>
        <button className="btn btn-danger" type="submit" onClick="concatenate()" disabled={state.submitting}>
          {formStatus}
        </button>
      </form>
    </div>
    </div>
  )
}
export default ContactForm